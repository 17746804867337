<template>
  <div>
    <p class="p-text-title">Histograma de entradas y salidas</p>
    <filter-input-outputs-component @setShowFor="setShowFor" @setTransition="setTransition" />
    <div class="content-line-chart">
      <p class="text-inventary">Inventario</p>
      <chart-component-global v-if="ObjEmptyGlobal(oGraphic)" :series="oGraphic.series" :screenWidth="screenWidth"
        :chartOptions="oGraphic.chartOptions" :sTypeChart="oGraphic.sTypeChart" />
      <p class="text-period">{{ sPeriod }}</p>
    </div>
    <v-divider class="divider-global mt-9"></v-divider>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      sPeriod: "Semanas",
      sShowFor: 1,
      aTransation: [],
      oGraphic: {},
    };
  },
  beforeMount() {
    this.getItem();
  },
  updated() {
    if (window.innerWidth > 960) {
      this.widthChart();
    }
  },
  created() {
    window.addEventListener("resize", this.widthChart);
    this.widthChart();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.widthChart);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.widthChart();
      } else {
        this.screenWidth = window.innerWidth - 65;
      }
    },
    widthChart() {
      if (this.bSidebar) {
        this.screenWidth = (window.innerWidth - 410) / 1;
      } else {
        this.screenWidth = (window.innerWidth - 207) / 1;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    setShowFor(sShowFor) {
      this.sShowFor = sShowFor;
      this.getItem()
    },
    setTransition(aTransation) {
      this.aTransation = aTransation.filter(e => e === "in" || e === "out")
      this.getItem()

    },
    getItem() {
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory/inout`,
        {
          params: {
            iPeriod: this.sShowFor,
            sInOut: this.aTransation.length > 1 ? null : this.aTransation[0],
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        }
      )
        .then((response) => {
          this.oGraphic = {
            sTitle: "Variación entre igresos y egresos",
            sTypeChart: "area",
            series: [
              {
                name: "Entradas",
                data: response.data.results.In,
              },
              {
                name: "Salidas",
                data: response.data.results.Out,
              },
            ],
            chartOptions: {
              chart: {
                width: 400,
                type: "area",
                zoom: {
                  enabled: false,
                  type: "x",
                  autoScaleYaxis: false,
                  zoomedArea: {
                    fill: {
                      color: "#90CAF9",
                      opacity: 0.4,
                    },
                    stroke: {
                      color: "#0D47A1",
                      opacity: 0.4,
                      width: 1,
                    },
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
              },
              legend: {
                position: 'top',
                horizontalAlign: 'right',
              },
              xaxis: {
                type: "int",
                categories: response.data.results.Periods,
              },
              yaxis: {
                labels: {
                  formatter: (value) => {
                    return value + " Kg";
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " Kg";
                    }
                    return y;
                  },
                  format: "Kg",
                },
                z: {
                  formatter: undefined,
                },
              },
            },
          }
          this.screenWidth = this.screenWidth - .00001
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    bSidebar() {
      this.widthChart();
    },
    sShowFor() {
      if (this.sShowFor === 1) {
        this.sPeriod = 'Semanas'
      } else {
        this.sPeriod = 'Meses'
      }
    }
  },
};
</script>

<style scoped>
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-filters {
  display: flex;
  width: 100%;
}

.content-filter-show-for {
  width: 25%;
  margin-right: 15px;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-line-chart {
  width: 100%;
  position: relative;
}

.text-inventary {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  left: -8px;
  bottom: 0;
  top: 20px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  letter-spacing: 14px;
}

.text-period {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  text-align: center;
  left: 0%;
  right: 0%;
  bottom: -15px;
  position: absolute;
  letter-spacing: 14px;
}

.content-filter-date {
  width: 25%;
}

.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .p-text-title {
    text-align: center;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }

  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 40%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 45%;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-show-for {
    width: 25%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 30%;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>